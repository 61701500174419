import type { ComponentProps } from 'react'

export const Logo = ({
  className,
  variant = 'color',
  ...props
}: ComponentProps<'svg'> & { variant?: 'color' | 'white' }) => {
  const fill = variant === 'color' ? '#0e0048' : '#fff'

  return (
    <svg
      preserveAspectRatio="xMidYMid meet"
      data-bbox="430.79 423.81 438.42 52.39"
      viewBox="430.79 423.81 438.42 52.39"
      xmlns="http://www.w3.org/2000/svg"
      data-type="color"
      aria-hidden="true"
      className={className}
      {...props}
    >
      <title>Logo</title>
      <g>
        <path
          fill="#2c75ff"
          d="m453.79 469.01 15.9 6.42v-26.5l-15.9-6.42v26.5z"
          data-color="1"
        />
        <path
          fill="#2c75ff"
          d="m486.28 442.51 6.42-15.89h-55.5l-6.41 15.89h55.49z"
          data-color="1"
        />
        <path
          d="M509.65 473.26c2.8 1.99 7.51 2.61 14.13 1.85v-8.87c-1.87.1-3.43.11-4.7.04-1.26-.07-2.23-.41-2.87-1.02-.66-.61-.99-1.57-.99-2.89v-12.63l-9.77-3.13v15.76c0 5.26 1.41 8.88 4.22 10.88Zm14.13-26.64v-9.4h-8.57v-10.61l-9.77 2.95v17.06h18.34Z"
          fill={fill}
          data-color="2"
        />
        <path
          d="M565.23 456.17c0-5.66-1.82-10.41-5.46-14.25-3.53-3.84-8.11-5.76-13.72-5.76-5.91 0-10.71 1.92-14.4 5.76-3.74 3.84-5.61 8.59-5.61 14.25s1.89 10.53 5.68 14.31c3.79 3.79 8.82 5.7 15.08 5.7 7.27 0 12.78-2.71 16.51-8.12l-7.87-4.54c-1.93 2.52-4.75 3.78-8.49 3.78-5.86 0-9.43-2.36-10.68-7.11h28.56c.26-1.62.39-2.96.39-4.02Zm-29.1-3.57c.55-2.42 1.69-4.3 3.41-5.65 1.72-1.33 3.89-2 6.52-2 2.33 0 4.33.66 6.02 1.96 1.7 1.32 2.82 3.21 3.38 5.68h-19.32Z"
          fill={fill}
          data-color="2"
        />
        <path
          d="M596.9 461.47a8.32 8.32 0 0 1-3.44 3.68c-1.54.88-3.3 1.32-5.27 1.32-2.92 0-5.35-.96-7.27-2.87-1.92-2.03-2.87-4.5-2.87-7.42s.96-5.52 2.87-7.47c1.93-1.94 4.35-2.91 7.27-2.91 1.93 0 3.66.45 5.2 1.36 1.54.92 2.69 2.15 3.44 3.72l8.42-4.92c-1.57-2.98-3.87-5.36-6.9-7.13-3.03-1.77-6.39-2.65-10.07-2.65-5.72 0-10.5 1.92-14.33 5.76-3.79 3.84-5.68 8.59-5.68 14.25s1.89 10.41 5.68 14.24c3.83 3.84 8.61 5.77 14.33 5.77 3.63 0 6.98-.9 10.04-2.66 3.06-1.77 5.42-4.17 7.09-7.2l-8.49-4.85Z"
          fill={fill}
          data-color="2"
        />
        <path
          d="M640.33 440.33c-2.62-2.79-6.03-4.17-10.22-4.17-5.21 0-8.98 1.77-11.29 5.3v-17.65l-9.79 2.96v48.34h9.79v-20.46c0-3.08.75-5.42 2.23-7.01 1.49-1.59 3.53-2.39 6.11-2.39 2.33 0 4.15.68 5.5 2.05 1.33 1.36 2 3.28 2 5.76v22.05h9.79v-23.27c0-4.85-1.37-8.69-4.1-11.52Z"
          fill={fill}
          data-color="2"
        />
        <path
          d="M678.68 458.97c-.76-1.47-1.8-2.64-3.15-3.52-1.33-.88-2.66-1.57-3.98-2.05-1.31-.47-2.77-.9-4.39-1.24-4.6-1.17-6.9-2.55-6.9-4.18 0-.91.42-1.65 1.26-2.23.83-.58 1.88-.87 3.15-.87 2.82 0 4.85 1.28 6.06 3.85l8.34-4.54c-1.47-2.57-3.47-4.56-5.99-5.96-2.52-1.38-5.32-2.08-8.41-2.08-4.09 0-7.54 1.08-10.31 3.26-2.74 2.11-4.09 5.05-4.09 8.79 0 1.87.36 3.49 1.09 4.89.73 1.39 1.8 2.52 3.22 3.41 1.42.88 2.74 1.55 3.94 2.01 1.22.45 2.67.91 4.4 1.36 4.59 1.17 6.89 2.55 6.89 4.17 0 2.18-1.74 3.26-5.22 3.26-3.79 0-6.27-1.63-7.44-4.92l-8.48 4.78c2.82 6.02 8.13 9.03 15.92 9.03 4.29 0 7.9-1.04 10.83-3.11 2.93-2.13 4.4-5.14 4.4-9.03 0-1.92-.39-3.61-1.14-5.07Z"
          fill={fill}
          data-color="2"
        />
        <path
          d="M716.35 442c-3.89-3.89-8.64-5.83-14.25-5.83s-10.35 1.94-14.24 5.83c-3.84 3.84-5.76 8.57-5.76 14.18s1.92 10.41 5.76 14.24c3.83 3.84 8.58 5.77 14.24 5.77s10.41-1.93 14.25-5.77c3.89-3.88 5.83-8.63 5.83-14.24s-1.94-10.28-5.83-14.18Zm-6.89 21.67c-1.98 1.96-4.43 2.96-7.36 2.96s-5.37-.99-7.35-2.96c-1.92-2.01-2.87-4.53-2.87-7.5s.96-5.48 2.87-7.51c1.98-1.96 4.43-2.95 7.35-2.95s5.38.98 7.36 2.95c1.96 1.98 2.95 4.48 2.95 7.51s-.98 5.53-2.95 7.5Z"
          fill={fill}
          data-color="2"
        />
        <path
          d="M752.57 437.22v20.47c0 3.07-.75 5.41-2.23 7-1.49 1.59-3.53 2.39-6.11 2.39-2.33 0-4.15-.68-5.5-2.04-1.34-1.37-2-3.28-2-5.76v-22.06h-9.79v23.27c0 4.85 1.34 8.69 4.02 11.52 2.74 2.79 6.17 4.18 10.31 4.18 5.21 0 8.97-1.78 11.29-5.31v4.24h9.79v-37.89h-9.79Z"
          fill={fill}
          data-color="2"
        />
        <path
          d="M782.36 438.32c-2.06 1.24-3.53 3.05-4.39 5.42v-6.52h-9.77v37.89h9.77V457c0-3.64 1.16-6.24 3.48-7.81 2.38-1.62 5.12-1.82 8.03-1.82v-10.16c-2.67 0-5.05-.14-7.13 1.09Z"
          fill={fill}
          data-color="2"
        />
        <path
          d="M820.48 461.47c-.76 1.57-1.9 2.8-3.44 3.68s-3.3 1.32-5.27 1.32c-2.93 0-5.36-.96-7.27-2.87-1.92-2.03-2.88-4.5-2.88-7.42s.97-5.52 2.88-7.47c1.92-1.94 4.34-2.91 7.27-2.91 1.92 0 3.66.45 5.2 1.36 1.53.92 2.69 2.15 3.44 3.72l8.41-4.92c-1.57-2.98-3.85-5.36-6.89-7.13-3.03-1.77-6.39-2.65-10.09-2.65-5.71 0-10.48 1.92-14.33 5.76-3.78 3.84-5.68 8.59-5.68 14.25s1.9 10.41 5.68 14.24c3.84 3.84 8.62 5.77 14.33 5.77 3.64 0 6.99-.9 10.05-2.66 3.05-1.77 5.41-4.17 7.08-7.2l-8.48-4.85Z"
          fill={fill}
          data-color="2"
        />
        <path
          d="M869.21 456.17c0-5.66-1.83-10.41-5.46-14.25-3.54-3.84-8.11-5.76-13.72-5.76-5.92 0-10.72 1.92-14.4 5.76-3.74 3.84-5.61 8.59-5.61 14.25s1.89 10.53 5.68 14.31c3.79 3.79 8.82 5.7 15.08 5.7 7.27 0 12.78-2.71 16.51-8.12l-7.88-4.54c-1.92 2.52-4.74 3.78-8.48 3.78-5.86 0-9.43-2.36-10.68-7.11h28.56c.26-1.62.39-2.96.39-4.02Zm-29.11-3.57c.56-2.42 1.7-4.3 3.42-5.65 1.72-1.33 3.88-2 6.52-2 2.33 0 4.33.66 6.02 1.96 1.69 1.32 2.82 3.21 3.37 5.68h-19.32Z"
          fill={fill}
          data-color="2"
        />
      </g>
    </svg>
  )
}
